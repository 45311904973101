<template>
  <div class="limited" id="limited">
    <Nav/>
      <main id="main" role="main">
        <section id="lead">
            <h2><img src="@/assets/img/head_limited.svg" alt="限定品"></h2>
            <ul class="column2-1">
              <li><img src="@/assets/img/limited_tshirts.png" alt=""><div class="box">
                <!-- <h3><img src="@/assets/img/head_limited_tshirts.svg" alt="オリジナルTシャツ"></h3> -->
                <h3>オリジナルTシャツ</h3>
                <p>クラウドファンディングで作った限定のTシャツです。</p>
              </div><a href="https://kumanokotai.base.shop/" target="_blank" class="nes-btn">オンラインで買う</a></li>
              <li><img src="@/assets/img/limited_goshuin.png" alt=""><div class="box">
                <!-- <h3><img src="@/assets/img/head_limited_goshuin.svg" alt="飛び出す御朱印"></h3> -->
                <h3>飛び出すご朱印</h3>
                <p>日本神社会を騒然とさせたここにしかない飛び出すご朱印。</p>
              </div><a href="https://kumanokotai.base.shop/" target="_blank" class="nes-btn">オンラインで買う</a></li>
            </ul>
            <ul class="column2-1">
              <li><img src="@/assets/img/limited_omamori.png" alt=""><div class="box">
                <h3>おまもり</h3>
                <p>しなの木のおまもりや、やたがらすのおまもりなど、ここでしか手に入らない限定品をご用意。</p>
              </div><a href="https://kumanokotai.base.shop/" target="_blank" class="nes-btn">オンラインで買う</a></li>
              <li><img src="@/assets/img/limited_omikuji.png" alt=""><div class="box">
                <h3>おみくじ</h3>
                <p>やたがらすのおみくじなど珍しいおみくじ。</p>
              </div><a href="https://kumanokotai.base.shop/" target="_blank" class="nes-btn">オンラインで買う</a></li>
            </ul>
          </section>
      </main>
    <Foot/>
  </div>
</template>

<script>
// @ is an alias to /src
import Foot from '@/components/Foot.vue'
import Nav from '@/components/Nav.vue'

export default {
  name: 'Limited',
  components: {
    Foot,
    Nav
  }
}
</script>

<style lang="scss" scoped>
  h3,
  p,a {
      font-family: 'NuKinakoMochi-BarVtE-1'
  }
</style>
